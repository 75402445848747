import React from "react"

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="flex items-center justify-center max-w-screen-xl px-4 py-12 mx-auto">
        <p className="text-base leading-6 text-center text-gray-600">
          &copy; {new Date().getFullYear()} Fundraisify. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
